import { Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCreateBackup, CreateBackupRequest } from 'api/backups';
import { useForm } from 'react-hook-form';
import { TextField } from 'component/new_design/base/forms/TextField';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { FormDialog } from 'component/new_design/base/dialogs/FormDialog';
import { CreateUserSchema, useAddSiteProtectionUser } from 'api/security';

interface AddPasswordProtectionUserDialogProps {
  readonly siteId: string;
  readonly onSuccess: () => unknown;
  readonly onClose: () => unknown;
}

export const AddPasswordProtectionUserDialog = ({
  siteId,
  onSuccess,
  onClose,
}: AddPasswordProtectionUserDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<CreateUserSchema>({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const addSiteProtectionUser = useAddSiteProtectionUser({ siteId });

  const onSubmit = async (values: CreateUserSchema) => {
    await addSiteProtectionUser.mutateAsync(values);

    enqueueSnackbar(t('user_invited_successfully'), { variant: 'success' });
    onSuccess();
    onClose();
  };

  return (
    <FormDialog
      form={
        <Stack gap={4}>
          <Typography variant="body2">
            {t('add_password_protetion_user_dialog_description')}
          </Typography>
          <TextField name="username" label={t('username')} />
          <TextField name="password" label={t('password')} type="password" />
        </Stack>
      }
      methods={methods}
      onSubmit={onSubmit}
      onClose={addSiteProtectionUser.isPending ? undefined : onClose}
      title={t('invite_user')}
      submitText={t('invite')}
    />
  );
};
